import React from 'react';

import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

//firebase
import auth from '../firebase/conf';

const SignOut = () => {


    const handleClick = async () => {
        await deleteDoc(doc(getFirestore(), "online", auth.currentUser.uid));
        auth.signOut();
    }

    return auth.currentUser && (
        <button className="sign-out" onClick={handleClick}>Sign Out</button>
    )
};

export default SignOut;