import React, { useEffect, useRef, useState } from 'react';

// firebase
import auth from '../firebase/conf';
import { getFirestore, collection, query, where, orderBy, limit, addDoc, doc, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes, } from 'firebase/storage';

// hook firebase
import { useCollection, useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

// hooks npm
import ScrollToBottom, { useScrollToBottom, useSticky } from 'react-scroll-to-bottom';
import Snackbar from '@mui/material/Snackbar';
import ChatMessage from './ChatMessage';
import MuiAlert from '@mui/material/Alert';

//hooks
import RecorderControls from "../components/recorder-controls";
import useRecorder from "./hooks/useRecorder";

import notification from '../assets/notification.mp3';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChatRoom = () => {
    const dummy = useRef();
    const fileInputImgRef = useRef(null);
    const fileInputAttachRef = useRef(null);
    const { recorderState, ...handlers } = useRecorder();
    const { audio, blob } = recorderState;

    const [features, setFeatures] = useState({ image: true, attach: false, notification: true, });
    const [notificationActivate, setNotificationActivate] = useState(false);

    const messagesRef = collection(getFirestore(), 'messages');
    const q = query(messagesRef, where("remove", "==", false), orderBy('createdAt', "desc"), limit(25));
    // , orderBy('createdAt', "desc"), limit(25)

    const [messages, loadingMessages, error] = useCollection(q);

    const onlineRef = collection(getFirestore(), 'online');
    const [usersOnline, loadingOnline, errorOnline] = useCollectionData(onlineRef);
    const [userOnline, loadingUserOnline] = useDocumentData(doc(getFirestore(), "online", auth.currentUser.uid));


    useEffect(() => {
        if (audio) {
            console.log("audio");

            // saveAudio();

            saveFileMessage(blob, "audio")

        }
    }, [audio]);

    async function saveAudio() {
        const { uid, displayName, photoURL, } = auth.currentUser;
        const messageRef = await addDoc(messagesRef, {
            audioUrl: audio,
            createdAt: serverTimestamp(),
            uid,
            displayName,
            photoURL,
            remove: false
        });
    }


    if (error) {
        console.log(error);
    }

    useEffect(() => {
        //console.log("useEffect1");
        if (!loadingMessages) {
            // console.log(messages);
            // console.log("useEffect2");
            dummy.current.scrollIntoView({ behavior: 'smooth' });

            playNotification();

        }
    }, [loadingMessages, messages]);

    const playNotification = () => {
        // console.log(messages.size);
        // console.log(messages.docs[messages.size - 1].data().uid);
        // console.log(messages);
        if (messages.size > 0) {
            const lastIdUser = messages.docs.reverse()[messages.size - 1].data().uid
            // console.log(lastIdUser, auth.currentUser.uid);
            if (features.notification && lastIdUser !== auth.currentUser.uid) {
                if (notificationActivate) {
                    const audioEl = document.getElementsByClassName("audio-element")[0]
                    audioEl.play();
                } else { setNotificationActivate(true) }
            }
        }

    }


    const [formValue, setFormValue] = useState('');

    // const [open, setOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbar({ ...snackbar, open: false });
    };

    const sendMessage = async (e) => {
        e.preventDefault();

        const { uid, displayName, photoURL, } = auth.currentUser;

        await addDoc(messagesRef, {
            text: formValue,
            createdAt: serverTimestamp(),
            uid,
            displayName,
            photoURL,
            remove: false
        })

        setFormValue('');
    }

    const handleImg = (e) => {
        e.preventDefault();
        fileInputImgRef.current?.click();
    };

    const handleAttach = (e) => {
        e.preventDefault();
        if (features.attach) {
            fileInputAttachRef.current?.click();
        } else {
            var data = {
                ...snackbar,
                message: "This feature has been disabled",
                timeout: 3000,
                open: true
            };

            setSnackbar(data);
        }

    };

    function onMediaFileSelected(event) {
        event.preventDefault();
        const { files, id } = event.target
        let file = files[0];

        // Clear the selection in the file picker input.
        // imageFormElement.reset();

        // Check if the file is an image.
        if (id === "img") {
            console.log(fileInputImgRef.current);
            fileInputImgRef.current.value = null;
            if (!file.type.match('image.*')) {
                var data = {
                    ...snackbar,
                    message: 'You can only share images',
                    timeout: 3000,
                    open: true
                };

                // console.log(data);
                setSnackbar(data);

                return;
            }
            saveFileMessage(file, "image");
        } else {
            fileInputAttachRef.current.value = null;
            saveFileMessage(file, "attach");
        }

    }

    async function saveFileMessage(file, type) {
        // console.log(file);
        const fileName = file.name || `${type}.ogg`;
        // console.log(fileName);

        const { uid, displayName, photoURL, } = auth.currentUser;

        try {

            const messageRef = await addDoc(messagesRef, {
                [type + "Url"]: 'https://www.google.com/images/spin-32.gif?a',
                [type + "Name"]: fileName,
                createdAt: serverTimestamp(),
                uid,
                displayName,
                photoURL,
                remove: false
            });

            // 2 - Upload the image to Cloud Storage.
            const filePath = `${auth.currentUser.uid}/${messageRef.id}/${fileName}`;
            const newImageRef = ref(getStorage(), filePath);
            const fileSnapshot = await uploadBytesResumable(newImageRef, file);

            // 3 - Generate a public URL for the file.
            const publicImageUrl = await getDownloadURL(newImageRef);

            // 4 - Update the chat message placeholder with the image's URL.
            await updateDoc(messageRef, {
                [type + "Url"]: publicImageUrl,
                storageUri: fileSnapshot.metadata.fullPath
            });
        } catch (error) {
            console.error('There was an error uploading a file to Cloud Storage:', error);
        }
    }

    const handleMicrophone = (e) => {
        e.preventDefault();

        var data = {
            ...snackbar,
            message: "This function doesn't exist at this time",
            timeout: 3000,
            open: true
        };

        setSnackbar(data);
    };

    const { cancelRecording } = handlers;

    return (<>
        <div className='sidebar'>
            <div className="sidebar-content">
                <p>Online ({usersOnline && usersOnline.length}) :</p>

                <ul>
                    {usersOnline && usersOnline.map((user, i) => <li key={i}>{user.displayName}</li>)}
                </ul>
            </div>
        </div>

        <div className='chat'>
            <ScrollToBottom>
                <main>

                    {messages &&
                        messages.docs.reverse().map((msg, i) => {
                            // console.log(messages.docs[i].data().uid);

                            const uidPrev = i > 0 ? messages.docs.reverse()[i - 1].data().uid : null
                            return <ChatMessage key={msg.id} id={msg.id} message={msg.data()} uidPrev={uidPrev} />;
                            //  
                        })
                    }
                    <span ref={dummy}></span>

                </main>
            </ScrollToBottom>


            <form className='chat-send' onSubmit={sendMessage}>

                <input className='message-send' value={formValue} onFocus={cancelRecording} onChange={(e) => setFormValue(e.target.value)} placeholder="Message" />

                <div className='outils'>
                    {formValue ?
                        <button className='btn-send' type="submit" disabled={!formValue}><span role="img" aria-label='fleche'>➡️</span></button>
                        :
                        <RecorderControls recorderState={recorderState} handlers={handlers} />
                    }


                    <div className='stack'>
                        <button onClick={handleImg} className='btn-img'><span role="img" aria-label='folder'>📁</span></button>
                        <button onClick={handleAttach} className='btn-clippy'><span role="img" aria-label='clippy'>📎</span></button>

                        <button onClick={handleMicrophone} className='btn-microphone'><span role="img" aria-label='microphone'>🎤</span></button>
                    </div>
                </div>


            </form>

            <form className='outils'>
                <input id="img" ref={fileInputImgRef} onChange={onMediaFileSelected} type="file" accept="image/*" capture="camera" style={{ display: 'none' }} />
                <input id="attach" ref={fileInputAttachRef} onChange={onMediaFileSelected} type="file" style={{ display: 'none' }} />
            </form>

            <Snackbar
                anchorOrigin={{ vertical: snackbar.vertical, horizontal: snackbar.horizontal }}
                open={snackbar.open}
                autoHideDuration={snackbar.timeout}
                onClose={handleClose}
                // message={snackbar.message}
                key={snackbar.vertical + snackbar.horizontal}
            >
                <Alert severity="warning">{snackbar.message}</Alert>

            </Snackbar>

            <audio className="audio-element">
                <source src={notification}></source>
            </audio>
        </div>

    </>)
};

export default ChatRoom;