import React, { useEffect, useRef, useState } from "react";

import "./App.css";

// firebase
import auth from "./firebase/conf";

//hook firebase
import {
  useAuthState,
  useSignInWithGoogle,
  useSignInWithEmailAndPassword,
  useCreateUserWithEmailAndPassword,
  useUpdateProfile,
} from "react-firebase-hooks/auth";

// const analytics = firebase.analytics();

//components
import SignIn from "./components/SignIn";
import ChatRoom from "./components/ChatRoom";
import SignOut from "./components/SignOut";

function App() {
  const [user, loading] = useAuthState(auth);
  // console.log(user);

  return (
    <div className="App">
      <header>
        <h1>
          <span role="img" aria-label="react">
            ⚛️🔥💬
          </span>
        </h1>
        <SignOut />
      </header>

      <section>
        {loading ? (
          "Chargement du chat en cours"
        ) : user ? (
          <ChatRoom />
        ) : (
          <SignIn />
        )}
      </section>
    </div>
  );
}

function Notification() {
  return (
    <audio className="audio-element">
      <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
    </audio>
  );
}

export default App;
