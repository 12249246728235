import React, { useEffect, useRef, useState } from 'react';

// firebase
import auth from '../firebase/conf';
import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';

//hook firebase
import {
    useAuthState,
    useSignInWithGoogle,
    useSignInWithEmailAndPassword,
    useCreateUserWithEmailAndPassword,
    useUpdateProfile,
} from 'react-firebase-hooks/auth';


const SignIn = () => {
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [user, loading] = useAuthState(auth);
    const [signInWithGoogle] = useSignInWithGoogle(auth);
    const [signInWithEmailAndPassword, userSignIn, loadingSignIN, errorSignIn] = useSignInWithEmailAndPassword(auth);
    const [createUserWithEmailAndPassword, userCreate, loadingCreate, errorCreate] = useCreateUserWithEmailAndPassword(auth);
    const [updateProfile, updating, errorUpdate] = useUpdateProfile(auth);


    if (errorCreate) {
        console.log(errorCreate);
    }

    if (errorUpdate) {
        console.log(errorUpdate);
    }

    if (updating) {
        console.log(updating);
    }


    useEffect(() => {
        if (errorSignIn) {
            if (errorSignIn.code === "auth/user-not-found") {
                createUser();
            }
        }
    }, [errorSignIn]);

    const createUser = async () => {
        await createUserWithEmailAndPassword(email, password);
        console.log(auth.currentUser.uid);
        await updateProfile({ displayName });
        addOnline();

    }

    const addOnline = async () => {
        const { uid, displayName } = auth.currentUser
        await setDoc(doc(getFirestore(), "online", uid), {
            displayName,
            uid,
            timestamp: serverTimestamp()
        })
    }


    const signIn = async (e, provider) => {
        console.log(e, provider);
        e.preventDefault();
        if (provider === "google") {
            await signInWithGoogle();
        } else {
            console.log("test0");
            await signInWithEmailAndPassword(email, password)
            console.log("test1");
        }
        // console.log("sign In");
        // console.log(errorSignIn);
        // createUserWithEmailAndPassword(email, password)

        addOnline();
    }

    return (
        <>
            <button className="sign-in" onClick={(e) => signIn(e, "google")}>Sign in with Google</button>
            <p>Or</p>

            <form onSubmit={(e) => signIn(e, "password")} className='signIn'>
                <label htmlFor="pseudo">Pseudo :</label>
                <input type="text" onChange={(e) => setDisplayName(e.target.value)} required />

                <label htmlFor="email">Email :</label>
                <input type="text" onChange={(e) => setEmail(e.target.value)} required />

                <label htmlFor="email">password :</label>
                <input type="password" onChange={(e) => setPassword(e.target.value)} required />

                <button className="sign-in">Sign in with Credential</button>
            </form>

            <p>Do not violate the community guidelines or you will be banned for life!</p>
        </>
    )
};

export default SignIn;