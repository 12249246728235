import React, { useEffect, useRef, useState } from 'react';

// firebase
import auth from '../firebase/conf';
import { getFirestore, collection, query, where, orderBy, limit, addDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';

//hooks
import FsLightbox from 'fslightbox-react';
import { format } from 'date-fns';
import { Menu, Item, Separator, Submenu, MenuProvider, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

const ChatMessage = (props) => {
    const { id, message, uidPrev } = props
    const { text, imageUrl, attachUrl, audioUrl, attachName, uid, displayName, photoURL, createdAt } = message;

    // const [showDisplayName, setShowDisplayName] = useState(true);
    const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
    const showClassFirstMessage = uid !== uidPrev ? 'first-message' : '';
    const showDisplayName = uid !== uidPrev ? messageClass === 'received' : false

    const MENU_ID = id;
    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const [toggler, setToggler] = useState(false);

    function displayMenu(e) {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show(e, { props: { id: e.currentTarget.id } });
    }

    const handleItemClick = ({ event, props }) => {
        //console.log(event, props);
        //console.log(event.currentTarget);

        switch (event.currentTarget.id) {
            case "remove":
                // logic to remove the row
                // console.log(props.id);
                removeMessage(props.id)
                break;
            case "copy":
                // logic to copy
                // console.log(props.id);
                navigator.clipboard.writeText(text)
                break;
            default:
                break;
        }
    };

    const removeMessage = async (id) => {

        const messageRef = doc(getFirestore(), "messages", id);

        await updateDoc(messageRef, {
            remove: true
        })
    }


    return (<>

        <FsLightbox toggler={toggler} sources={[imageUrl]} />

        <Menu id={MENU_ID}>
            <Item>Message de : {displayName}</Item>
            {text && <Item id="copy" onClick={handleItemClick}>Copy message link</Item>}
            <Item id="remove" onClick={handleItemClick}>Remove</Item>
            <Separator />
            <Item disabled>Disabled</Item>
        </Menu>

        <div id={id} onContextMenu={displayMenu} >
            <div className={`message ${messageClass} ${showClassFirstMessage}`}>
                <span className='avatar'>
                    {showDisplayName &&
                        <img src={photoURL || 'https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-vector-user-young-boy-avatar-icon-png-image_1538408.jpg'} alt="profile" />}
                </span>

                <p>
                    {showDisplayName &&
                        <span className='displayName'>{displayName}</span>
                    }
                    <span className="message">
                        {text && text}
                        {imageUrl &&
                            <span>
                                <img onClick={() => setToggler(!toggler)} src={imageUrl} alt="" />
                            </span>
                        }
                        {attachUrl && attachName &&
                            <span><a href={attachUrl}>{attachName}</a></span>
                        }
                        {audioUrl &&
                            <audio controls src={audioUrl} />
                        }
                    </span> {createdAt && <span className='hours'>{format(new Date(createdAt.seconds * 1000), 'HH:mm')}</span>}
                </p>

            </div>
        </div>

    </>)
};

export default ChatMessage;